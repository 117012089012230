import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/layout';
import Text from '../components/page/text';

export const query = graphql`
  {
    hope {
      allPageTexts(where: { page: About }, orderBy: "order") {
        heading
        text
        columns
      }
    }
  }
`;

const About = ({ data }) => (
  <Layout>
    <Container style={{ padding: '0 10%' }}>
      <Row>
        <Col>
          <h1 style={{ marginLeft: '-10%' }}>About</h1>
        </Col>
      </Row>
      {data.hope.allPageTexts.map(text => (
        <Text key={text.heading} {...text} />
      ))}
    </Container>
  </Layout>
);

About.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default About;
